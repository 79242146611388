import React from "react";
import { NavLink } from "react-router-dom";
import css from "./menuMobile.module.css";
import { ReactComponent as SvgOficina } from "../../../../assets/icons/servicos/svg_consultadoria.svg";
import { ReactComponent as SvgSeguros } from "../../../../assets/icons/servicos/svg_seguros.svg";
const logo = "/assets/img/eirizcar.webp";
export default function MenuServicos({
  allinfoText,
  infoJson,
  nodeRef,
  onHide,
}) {
  return (
    <div ref={nodeRef} className={`${css.seconddiv} boxMenuMore`}>
      <ul className={`navbar-nav `}>
        {infoJson.RentFaq.existe && (
          <li>
            <NavLink
              onClick={onHide}
              className={(navData) =>
                navData.isActive ? "active boxMenuItem" : "boxMenuItem"
              }
              to={
                allinfoText.RentFaq && allinfoText.RentFaq.Url !== ""
                  ? allinfoText.RentFaq.Url
                  : infoJson.RentFaq.url
              }
            >
              <SvgOficina />{" "}
              {allinfoText.RentFaq && allinfoText.RentFaq.Titulo !== ""
                ? allinfoText.RentFaq.Titulo
                : infoJson.RentFaq.titulo}
            </NavLink>
          </li>
        )}

        {infoJson.RentProfissionais.existe && (
          <li>
            <NavLink
              onClick={onHide}
              className={(navData) =>
                navData.isActive ? "active boxMenuItem" : "boxMenuItem"
              }
              to={
                allinfoText.RentProfissionais &&
                allinfoText.RentProfissionais.Url !== ""
                  ? allinfoText.RentProfissionais.Url
                  : infoJson.RentProfissionais.url
              }
            >
              <SvgSeguros />{" "}
              {allinfoText.RentProfissionais &&
              allinfoText.RentProfissionais.Titulo !== ""
                ? allinfoText.RentProfissionais.Titulo
                : infoJson.RentProfissionais.titulo}
            </NavLink>
          </li>
        )}

        <li>
          <a target="_blank" href={"https://www.eirizcar.pt/"} rel="noreferrer">
            <img
              style={{
                marginRight: "10px",
                height: "20px",
                width: "auto",
              }}
              src={logo}
              alt="Eirizcar"
            />{" "}
            Eirizcar
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href={" https://www.eirizcar.pt/oficina/"}
            rel="noreferrer"
          >
            <img
              style={{
                marginRight: "10px",
                height: "20px",
                width: "auto",
              }}
              src={logo}
              alt="Eirizcar"
            />{" "}
            Oficina
          </a>
        </li>
      </ul>
    </div>
  );
}
