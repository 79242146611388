import React from "react";
import cssF from "../../../modal/faleConnosco.module.css";
import { ReactComponent as Fechar } from "../../../../assets/icons/modal/fechar.svg";
import { ReactComponent as SvgOficina } from "../../../../assets/icons/servicos/svg_consultadoria.svg";
import { ReactComponent as SvgSeguros } from "../../../../assets/icons/servicos/svg_seguros.svg";
import { NavLink } from "react-router-dom";
const logo = "/assets/img/eirizcar.webp";
export default function MenuServicosBottom({
  onHide,
  infoJson,
  allinfoText,
  nodeRef,
}) {
  return (
    <>
      <div className={cssF.zone}></div>
      <section
        ref={nodeRef}
        className={` ${cssF.box} ${cssF.height} listagem_info `}
      >
        <div onClick={() => onHide()} className={` ${cssF.close} close`}>
          <Fechar />
        </div>
        <section className={cssF.retangle}>
          <ul className={``}>
            {infoJson.RentFaq.existe && (
              <li>
                <NavLink
                  onClick={onHide}
                  className={(navData) =>
                    navData.isActive ? "active boxMenuItem" : "boxMenuItem"
                  }
                  to={
                    allinfoText.RentFaq && allinfoText.RentFaq.Url !== ""
                      ? allinfoText.RentFaq.Url
                      : infoJson.RentFaq.url
                  }
                >
                  <SvgOficina />{" "}
                  {allinfoText.RentFaq && allinfoText.RentFaq.Titulo !== ""
                    ? allinfoText.RentFaq.Titulo
                    : infoJson.RentFaq.titulo}
                </NavLink>
              </li>
            )}
            {infoJson.RentProfissionais.existe && (
              <li>
                <NavLink
                  onClick={onHide}
                  className={(navData) =>
                    navData.isActive ? "active boxMenuItem" : "boxMenuItem"
                  }
                  to={
                    allinfoText.RentProfissionais &&
                    allinfoText.RentProfissionais.Url !== ""
                      ? allinfoText.RentProfissionais.Url
                      : infoJson.RentProfissionais.url
                  }
                >
                  <SvgSeguros />{" "}
                  {allinfoText.RentProfissionais &&
                  allinfoText.RentProfissionais.Titulo !== ""
                    ? allinfoText.RentProfissionais.Titulo
                    : infoJson.RentProfissionais.titulo}
                </NavLink>
              </li>
            )}
            <li>
              <a
                target="_blank"
                href={"https://www.eirizcar.pt/usadas/#listagem_viaturas"}
                rel="noreferrer"
              >
                <img
                  style={{
                    marginRight: "10px",
                    height: "20px",
                    width: "auto",
                  }}
                  src={logo}
                  alt="Eirizcar"
                />{" "}
                Eirizcar
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href={" https://www.eirizcar.pt/oficina/"}
                rel="noreferrer"
              >
                <img
                  style={{
                    marginRight: "10px",
                    height: "20px",
                    width: "auto",
                  }}
                  src={logo}
                  alt="Eirizcar"
                />{" "}
                Oficina
              </a>
            </li>
          </ul>
        </section>
      </section>
    </>
  );
}
